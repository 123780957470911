<template>
  <v-card
    v-ripple="{ class: `secondary--text` }"
    class="mx-auto py-1"
    :class="{
      'my-5': $vuetify.breakpoint.smAndDown,
      'my-7': $vuetify.breakpoint.mdAndUp
    }"
    :to="to"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title
          class="text-h5 black--text"
          :class="{
            'text-h5': $vuetify.breakpoint.smAndDown
          }"
        >
          {{ title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: 'NavCard',
  props: ['title', 'icon', 'to']
}
</script>

<style lang="sass" scoped></style>
