<template>
  <v-container fill-height fluid class="pb-10">
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <transition name="slide-up" appear>
          <v-form lazy-validation>
            <v-icon color="primary" class="mb-4" x-large>fa-key</v-icon>
            <h2 class="mb-4">Enter your passcode</h2>
            <v-otp-input
              id="pin"
              ref="passcode"
              name="pin"
              class="otp-centered"
              input-classes="otp-input"
              input-type="tel"
              separator=""
              :num-inputs="4"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-complete="tryPin"
            />
            <p v-if="!pinResult" class="pt-5">Wrong passcode! Try again?</p>
          </v-form>
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('vaults', ['pin', 'pinResult'])
  },
  watch: {
    pin(workingPin) {
      const nextPath = window.location.search.split('next=')[1]
      if (workingPin) {
        this.$router.push(nextPath ? `/${nextPath}` : '/categories')
      }
    }
  },
  mounted() {
    this.resetPin()
  },
  methods: {
    ...mapActions('vaults', ['tryPin', 'resetPin'])
  }
}
</script>

<style lang="scss">
.otp-centered {
  justify-content: center;
}
</style>
