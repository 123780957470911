<template>
  <v-container class="pt-0">
    <v-container class="d-flex justify-center pt-0">
      <div>
        <h1 class="text-h4 black--text">Add password</h1>
      </div>
    </v-container>
    <transition name="slide-up" appear>
      <v-container>
        <v-form ref="form" lazy-validation @submit="handleSubmit">
          <v-text-field
            v-model="name"
            outlined
            label="Service name"
            hint="ex. Comcast, Bank of America, AT&T"
            required
            :rules="[rules.required]"
          ></v-text-field>
          <v-select
            v-model="category"
            outlined
            :items="[
              'Banking',
              'Government',
              'Telecom',
              'Media',
              'Healthcare',
              'Utilities',
              'Shopping',
              'Other'
            ]"
            label="Category"
            persistent-hint
            return-object
            single-line
            :rules="[rules.required]"
          ></v-select>
          <v-text-field
            label="Username"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="password"
            outlined
            label="Password"
            required
            :rules="[rules.required]"
            :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-textarea v-model="note" outlined label="Note"></v-textarea>
          <v-btn type="submit" color="black white--text" depressed block x-large
            >Add Password</v-btn
          >
        </v-form>
      </v-container>
    </transition>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      showPassword: false,
      name: '',
      password: '',
      category: '',
      note: '',
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  computed: {
    ...mapGetters('passwords', ['allPasswords', 'numPasswords'])
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.upsertPassword({
        name: this.name,
        category: this.category.toLowerCase(),
        password: this.password,
        note: this.note
      })
      this.$router.push('/?added')
    },
    ...mapActions('passwords', ['upsertPassword'])
  }
}
</script>

<style lang="sass" scoped></style>
