<template>
  <v-container>
    <v-container class="d-flex justify-center pt-0 pb-6">
      <h1 class="category-header">{{ $route.params.category }}</h1>
    </v-container>
    <v-container class="passwords">
      <PasswordCard
        v-for="pass of passwords"
        :title="pass.name"
        :key="pass.name"
        :to="`/password/${pass.name}`" />
    </v-container>
  </v-container>
</template>

<script>
import PasswordCard from '@/components/PasswordCard.vue'
import { mapGetters } from 'vuex'

export default {
  computed: {
    categoryName() {
      return this.$route.params.category
    },
    passwords() {
      const catPasses = this.categoryPasswords(this.categoryName)
      return catPasses
    },
    ...mapGetters("passwords", ["categoryPasswords"])
  },
  components: {
    PasswordCard
  }
}
</script>

<style lang="scss" scoped>
.category-header {
  text-transform: capitalize;
}
</style>
