<template>
  <v-container class="pt-0">
    <v-container class="d-flex justify-center pt-0">
      <div>
        <h1 class="text-h4 black--text">Passwords</h1>
      </div>
    </v-container>
    <transition name="slide-up" appear>
      <v-container>
        <NavTile
          title="Banking"
          color="green"
          icon="fa-university"
          to="/passwords/banking"
        />
        <NavTile
          title="Government"
          color="blue darken-1"
          icon="fa-file-contract"
          to="/passwords/government"
        />
      <NavTile
        title="Telecom"
        color="amber"
        icon="fa-phone"
        to="/passwords/telecom" />
        <NavTile title="Media" color="orange" icon="fa-tv" to="/passwords/media" />
        <NavTile
          title="Healthcare"
          color="red"
          icon="fa-heartbeat"
          to="/passwords/healthcare"
        />
        <NavTile
          title="Utilities"
          color="light-blue"
          icon="fa-burn"
          to="/passwords/utilities"
        />
        <NavTile
          title="Shopping"
          color="purple"
          icon="fa-shopping-bag"
          to="/passwords/shopping"
        />
        <NavTile title="Other" color="gray" icon="fa-key" to="/passwords/other" />
      </v-container>
    </transition>
  </v-container>
</template>

<script>
import NavTile from '@/components/NavTile.vue'

export default {
  components: {
    NavTile
  }
}
</script>

<style lang="sass" scoped></style>
