<template>
  <v-container class="pt-0">
    <v-container class="d-flex justify-center pt-0">
      <div>
        <h1 class="text-h4 black--text">Comcast</h1>
      </div>
    </v-container>
    <transition name="slide-up" appear>
      <v-container>
        <v-form ref="form" lazy-validation>
          <v-text-field
            v-model="name"
            label="Username"
            solo
            flat
            outlined
            readonly
          ></v-text-field>
          <v-container class="d-flex justify-end pa-0">
            <v-btn
              v-clipboard:copy="name"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              color="black white--text"
              depressed
              large
              class="mb-8 mt-n4"
              ><v-icon left>fa-copy</v-icon> Copy username</v-btn
            >
          </v-container>
          <v-text-field
            v-model="password"
            solo
            flat
            outlined
            label="Password"
            readonly
            required
            :rules="[rules.required]"
            :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-container class="d-flex justify-end pa-0">
            <v-btn
              v-clipboard:copy="password"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              color="black white--text"
              depressed
              large
              class="mb-8 mt-n4"
              ><v-icon left>fa-copy</v-icon> Copy password</v-btn
            >
          </v-container>
          <v-textarea
            v-model="note"
            solo
            flat
            outlined
            label="Note"
            value="My note"
            readonly
            hint="ex. Login instructions, support phone number..."
          ></v-textarea>
          <v-btn color="black white--text" depressed block x-large
            >Edit password</v-btn
          >
        </v-form>
      </v-container>
    </transition>
    <v-snackbar v-model="copySnackbar">
      {{ copySnackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="copySnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      copySnackbar: false,
      copySnackbarText: '',
      showPassword: false,
      name: 'testing',
      password: 'testing',
      note: 'my note',
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  computed: {
    ...mapGetters('passwords', ['allPasswords', 'numPasswords'])
  },
  methods: {
    onCopy() {
      this.copySnackbar = true
      this.copySnackbarText = 'Copied to clipboard'
    },
    onError(e) {
      this.copySnackbar = true
      this.copySnackbarText = 'Failed to copy'
      console.log(e)
    }
  }
}
</script>

<style lang="sass" scoped></style>
