<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <v-container>
          <v-icon v-if="step === '0'" color="primary" class="mb-4 mt-2" x-large
            >fa-lock</v-icon
          >
          <v-icon v-else color="primary" class="mb-4" x-large>fa-key</v-icon>
          <h1 v-if="step === '0'" class="text-h4 black--text">
            Vault secret
          </h1>
          <h1 v-if="step === '1'" class="text-h4 black--text">App passcode</h1>
          <h1 v-if="step === '2'" class="text-h4 black--text">
            Confirm passcode
          </h1>
        </v-container>

        <transition name="fade">
          <div v-if="step === 'creating-vault'" class="page-loader">
            <v-img src="@/assets/img/preloader_puff.svg"></v-img>
          </div>
        </transition>

        <v-container class="pb-0">
          <h2 v-if="step === '0'" class="text-h6 black--text">
            Write down the following 12 words in a safe space. You‘ll need them
            to recover your vault if you lose your phone.
          </h2>
          <h2 v-if="step === '1'" class="text-h6 black--text pb-8">
            Choose a 4-digit passcode.<br />You'll use it to unlock your vault.
          </h2>
          <h2 v-if="step === '2'" class="text-h6 black--text pb-8">
            Enter your passcode again.<br />Be sure to remember this!
          </h2>
        </v-container>

        <transition name="fade">
          <v-container>
            <transition name="slide-up" appear>
              <div v-if="step === '0'">
                <v-card class="mt-4 mb-4">
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-list dense>
                          <v-list-item
                            v-for="(seedWord, i) in seedMnemonic.split(' ')"
                            :key="`seedWord-${i}`"
                            class="text-h6"
                            ><span class="grey--text mr-2">{{
                              `${i + 1}.`.padStart(3, '0')
                            }}</span
                            >{{ seedWord }}</v-list-item
                          >
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                <v-form ref="form" lazy-validation>
                  <v-row align="center" justify="center" class="py-3">
                    <v-checkbox
                      v-model="wroteDownSeed"
                      label="I wrote down the words in exact order"
                      required
                    ></v-checkbox>
                  </v-row>
                  <v-btn
                    color="black white--text"
                    class="mt-3"
                    depressed
                    block
                    x-large
                    :disabled="!wroteDownSeed"
                    @click="step = '1'"
                    >Proceed</v-btn
                  >
                </v-form>
              </div>
            </transition>
            <transition name="slide-up" appear>
              <v-form v-if="step === '1'" lazy-validation>
                <v-otp-input
                  id="otp1"
                  ref="passcode"
                  v-model="firstOtp"
                  name="otp1"
                  class="otp-centered"
                  input-classes="otp-input"
                  input-type="tel"
                  separator=""
                  :num-inputs="4"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-complete="submitOtp1"
                />
                <span v-if="otpMismatch" class="validation-message">
                  The passcodes you entered don't match. Please be careful,
                  you'll need to remember this!
                </span>
              </v-form>
            </transition>
            <!-- NOTE! This needs to be a different component / element type than the v-form above -->
            <div v-if="step === '2'">
              <v-otp-input
                id="otp2"
                v-model="secondOtp"
                name="otp2"
                class="otp-centered"
                input-classes="otp-input"
                input-type="tel"
                separator=""
                :num-inputs="4"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-complete="submitOtp2"
              />
            </div>
          </v-container>
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

const bip39 = require('bip39')

export default {
  data() {
    return {
      step: '0', // '1' | '2' | 'creating-vault'
      wroteDownSeed: false,
      vaultSeed: null,
      firstOtp: null,
      secondOtp: null,
      otpMismatch: false,
      seedMnemonic: bip39.generateMnemonic()
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('vaults', ['hasVault'])
  },
  watch: {
    hasVault(value) {
      if (value) {
        this.$router.push('/')
      }
    }
  },
  methods: {
    submitOtp1(val) {
      console.log('test')
      this.otpMismatch = false
      this.firstOtp = val
      this.step = '2'
    },
    submitOtp2(val) {
      if (val === this.firstOtp) {
        // good match, proceed to create vault
        this.newVault(val)
        this.step = 'creating-vault'
        // TODO: Wait for vault creation then redirect
        setTimeout(() => {
          this.$router.push('/')
        }, 3000)
      } else {
        // go back a step
        this.firstOtp = null
        this.otpMismatch = true
        this.step = '1'
      }
    },
    ...mapActions('authentication', ['newVault'])
  }
}
</script>

<style lang="scss">
.otp-centered {
  justify-content: center;
}
</style>
