var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0"},[_c('v-container',{staticClass:"d-flex justify-center pt-0"},[_c('div',[_c('h1',{staticClass:"text-h4 black--text"},[_vm._v("Add password")])])]),_c('transition',{attrs:{"name":"slide-up","appear":""}},[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":_vm.handleSubmit}},[_c('v-text-field',{attrs:{"outlined":"","label":"Service name","hint":"ex. Comcast, Bank of America, AT&T","required":"","rules":[_vm.rules.required]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-select',{attrs:{"outlined":"","items":[
            'Banking',
            'Government',
            'Telecom',
            'Media',
            'Healthcare',
            'Utilities',
            'Shopping',
            'Other'
          ],"label":"Category","persistent-hint":"","return-object":"","single-line":"","rules":[_vm.rules.required]},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('v-text-field',{attrs:{"label":"Username","outlined":""}}),_c('v-text-field',{attrs:{"outlined":"","label":"Password","required":"","rules":[_vm.rules.required],"append-icon":_vm.showPassword ? 'fa-eye' : 'fa-eye-slash',"type":_vm.showPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-textarea',{attrs:{"outlined":"","label":"Note"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),_c('v-btn',{attrs:{"type":"submit","color":"black white--text","depressed":"","block":"","x-large":""}},[_vm._v("Add Password")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }