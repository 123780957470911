<template>
  <v-container>
    <v-container class="d-flex justify-center pb-8">
      <h1 class="text-h4 black--text">Settings</h1>
    </v-container>
    <transition name="slide-up" appear>
      <v-card class="py-3">
        <v-container>
          <v-subheader class="text-h5 black--text">Passcode</v-subheader>
          <v-divider class="mb-3"></v-divider>
          <v-form ref="form" lazy-validation @submit="submitForm">
            <v-otp-input
              ref="passcode"
              input-type="tel"
              separator=""
              :num-inputs="4"
              :should-auto-focus="true"
              :is-input-num="true"
            />
            <v-btn
              type="submit"
              color="primary"
              class="mt-3"
              depressed
              block
              large
              >Update my passcode</v-btn
            >
          </v-form>

          <v-subheader class="text-h5 black--text mt-6"
            >Trusted contacts</v-subheader
          >
          <v-divider class="mb-3"></v-divider>
          <v-form ref="form" lazy-validation>
            <v-text-field
              outlined
              label="Email"
              placeholder="myson@example.com"
              required
              hide-details
            ></v-text-field>
            <v-btn color="primary" class="mt-3" depressed block x-large
              >Share all my passwords</v-btn
            >
          </v-form>
          <v-list single-line>
            <v-list-item class="mt-4">
              <v-list-item-avatar>
                <v-img
                  src="https://cdn.vuetifyjs.com/images/lists/1.jpg"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Laetitia Nguyen</v-list-item-title>
                <v-list-item-subtitle>text@example.com</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>fa-times</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <v-btn outlined class="mt-8" depressed block x-large @click="logout"
            >Sign out</v-btn
          >
          <v-btn
            outlined
            color="red darken-1"
            class="mt-3"
            depressed
            block
            x-large
            >Delete account</v-btn
          >
        </v-container>
      </v-card>
    </transition>
  </v-container>
</template>

<script>
import firebase from 'firebase/app'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapActions('authentication', ['tryPin']),
    ...mapState('authentication', ['user'])
  },
  methods: {
    submitForm(e) {
      e.preventDefault()
      console.log('submit!')
      // how to invoke authentication.actions.tryPin( this.passcode );
      // this.refs.passcode ? v-model?
      this.tryPin('1234')
    },
    async logout() {
      await firebase.auth().signOut()
    }
  }
}
</script>

<style lang="sass" scoped></style>
